
import { Injectable } from '@angular/core';
import { from, of, Observable, Subject, combineLatest, throwError, concat, race } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, map, switchMap, take } from 'rxjs/operators';

@Injectable()

export class AuthServiceWrapper {

  loggedIn = true;

  get userIsAuthenticated(){
    return this.loggedIn;
  }

  constructor(
    // private auth: AuthService
  ){

  }

}


