import { SoffWorkOrderPermission } from "../../models/account/auth.model.sto";
import { DsmLicenseEnum } from "../../models/account/workshop.sto";

export interface SideNavigationItem {
    icon:               string;
    labelI18nKey:       string;
    routerLink:         string;
    dsmLicenseNeeded?:  (keyof typeof DsmLicenseEnum)[];
    permissionNeeded?:  string[];
    isProductionReady?: boolean;
};


export const SideNavigationItems: SideNavigationItem[] = [
    {
        icon:           'incoming-topics.svg',
        labelI18nKey:   'global.navigation.incomingTopics',
        routerLink:     '/app/incoming-topics'
    },
    {
        icon:           'work-orders.svg',
        labelI18nKey:   'global.navigation.work-orders',
        routerLink:     '/app/field-service/work-orders',
        dsmLicenseNeeded: [DsmLicenseEnum.PRO, DsmLicenseEnum.PLUS],
        permissionNeeded: [ SoffWorkOrderPermission.WORKORDER_CREATE ], // only visble for Foremen
        isProductionReady: true,
    },
    {
         icon:           'calendar.svg',
         labelI18nKey:   'global.navigation.calendar',
         routerLink:     '/app/field-service/resource-planning',
         dsmLicenseNeeded: [DsmLicenseEnum.PRO, DsmLicenseEnum.PLUS],
         permissionNeeded: [ SoffWorkOrderPermission.WORKORDER_CREATE ], // only visible for Foremen
         isProductionReady: true,
    },
    {
        icon:           'machines.svg',
        labelI18nKey:   'global.navigation.machines',
        routerLink:     '/app/machines/index/list'
    },
    {
        icon:           'employees.svg',
        labelI18nKey:   'global.navigation.employees',
        routerLink:     '/app/employees',
        dsmLicenseNeeded: [DsmLicenseEnum.PRO, DsmLicenseEnum.PLUS],
        permissionNeeded: [ SoffWorkOrderPermission.WORKORDER_CREATE ], // only visible for Foremen
        isProductionReady: true,
    },
    {
        icon:           'customers.svg',
        labelI18nKey:   'global.navigation.customers',
        routerLink:     '/app/customers'
    },
    {
        icon:           'servicevans.svg',
        labelI18nKey:   'global.navigation.servicevans',
        routerLink:     '/app/servicevans'
    },
    {
        icon:           'technical-documentation.svg',
        labelI18nKey:   'global.navigation.technicalDocumentation',
        routerLink:     '/app/technical-documentation'
    },
    // {
    //     icon:           'technical-documentation.svg',
    //     labelI18nKey:   'global.navigation.privacy',
    //     routerLink:     '/app/privacy/en'
    // },
    // {
    //     icon:           'technical-documentation.svg',
    //     labelI18nKey:   'global.navigation.disclaimer',
    //     routerLink:     '/app/terms-of-use/en'
    // },
];