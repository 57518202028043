import { ActionReducerMap, ActionReducer, MetaReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import * as RouterStore from '@ngrx/router-store';
import { environment } from '../../../environments/environment';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */


import { LayoutReducer, LayoutEffects } from './layout';
import { AccountReducers, AccountEffects } from './account';
import { NotificationEffects, NotificationReducer } from './notification';
import { StoreConstants } from '../models/store.model';
import { EmployeeReducer } from 'src/app/features/employees/state';
import { EmployeeEffects } from 'src/app/features/employees/state/effects';
import { FieldServiceReducer } from 'src/app/features/field-service/state';
import { FieldServicePartEffects } from 'src/app/features/field-service/state/effects.part';
import { FieldServiceTaskEffects } from 'src/app/features/field-service/state/effects.task';
import { FieldServiceWorkOrderEffects } from 'src/app/features/field-service/state/effects.workorder';
import { FieldServiceTimesheetEffects } from 'src/app/features/field-service/state/effects.timesheet';



export type StateError = Error | string | null;

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  rsAccount: AccountReducers.AccountState;
  rsLayout: LayoutReducer.State;
  rsNotifications: NotificationReducer.State;
  
  // TODO make modularization work again :) Only put into the
  // root store in order to make feature selectors working 
  // again  
  fsEmployees: EmployeeReducer.EmployeeState;
  fsFieldService: FieldServiceReducer.FieldServiceState;

}

/**
 * Re-export all of our shared Effects.
 *
 * Registered in the `core` ngModule
 */
export const effects = [
  AccountEffects,
  LayoutEffects,
  NotificationEffects,

  // TODO make modularization work again :) Only put into the
  // root store in order to make feature selectors working 
  // again
  EmployeeEffects,
  FieldServicePartEffects,
  FieldServiceTaskEffects, 
  FieldServiceWorkOrderEffects,
  FieldServiceTimesheetEffects,
];

 export const reducers: ActionReducerMap<State, any> = {
  rsAccount: AccountReducers.reducer,
  rsLayout: LayoutReducer.reducer,
  rsNotifications: NotificationReducer.reducer,

  // TODO make modularization work again :) Only put into the
  // root store in order to make feature selectors working 
  // again
  fsEmployees: EmployeeReducer.reducer,
  fsFieldService: FieldServiceReducer.reducer,
 };




