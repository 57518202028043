
<div class="mat-app-background" style="width: 100%;">

  <!-- Top Navigation -->
  <!-- <top-navbar *ngIf="!isOnIntroPage && !isOnDisclaimer && !isOnPrivacy" [isSidenavOpen]="isExpanded" (sidenavToggleEvent)="getActionFromNavbar($event)"></top-navbar> -->
  <top-navbar  [isSidenavOpen]="isExpanded" (sidenavToggleEvent)="getActionFromNavbar($event)" *ngIf="!isLoginPage"></top-navbar>
  <mat-drawer-container [ngClass]="{ 'fullscreen-container-wo-header' : isLoginPage, 
                                     'fullscreen-container-w-header': !isLoginPage }" class="mat-typography" autosize>
    
    <!-- Side Navigation -->
    <mat-drawer *ngIf="!isOnIntroPage && !isOnLegalPage && !!(isLoggedIn$ | async)" #dsmSideNavigation mode="side" disableClose="false" opened="true" class="left-sidenav">
      <mat-nav-list style="overflow: hidden;" class="nav-container" [ngClass]="{'opened': !!(sideNavOpened$ | async)}">
        <ng-container *ngFor="let sideNavigationItem of sideNavigationItems">
          <ng-container *ngIf="accessGuard.isAccessOk( sideNavigationItem?.dsmLicenseNeeded, 
                                                       sideNavigationItem?.permissionNeeded,
                                                       sideNavigationItem.isProductionReady) | async">
            <mat-list-item routerLink="{{ sideNavigationItem.routerLink }}" 
                                routerLinkActive="nav-item-active"
                                [routerLinkActiveOptions]="{exact: false}">
                  <!-- Navigation closed-->
                  <div *ngIf="!(sideNavOpened$ | async) else navigationOpened">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                      <mat-icon class="nav-item" [svgIcon]="'base:'+sideNavigationItem.icon"></mat-icon>
                    </div>
                  </div>
                  
                  <!-- Navigation opened -->
                  <ng-template #navigationOpened>
                    <div style="display: flex; flex-direction: row; align-items: center;">
                      <mat-icon class="nav-item"  [svgIcon]="'base:'+sideNavigationItem.icon"></mat-icon>
                      <div style="margin-left: 8px;">
                        {{ sideNavigationItem.labelI18nKey | translate }}
                      </div>
                    </div>
                  </ng-template>
            </mat-list-item>
          </ng-container>  
        </ng-container>
                
          <!-- <h4 mat-line *ngIf="!!(sideNavOpened$ | async)">
            <mat-icon class="nav-item" [svgIcon]="'base:'+sideNavigationItem.icon"></mat-icon> 
          </h4> -->



        <mat-list-item class="customLIPrivacy" (click)="showPrivacyBreadcrumb()"
                      routerLink="/legal/privacy/web" 
                      routerLinkActive="nav-item-active"
                      [routerLinkActiveOptions]="{exact: false}">
                
                <!-- Navigation closed-->
                <div *ngIf="!(sideNavOpened$ | async) else navigationOpenedPrivacy">
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="assets/icons/DSI.png" width="25">

                  </div>
                </div>
                
                <!-- Navigation opened -->
                <ng-template #navigationOpenedPrivacy>
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="assets/icons/DSI.png" width="25">
                    <div style="margin-left: 8px;">
                      {{ 'global.navigation.privacy' | translate }}
                    </div>
                  </div>
                </ng-template>
          <!-- <h4 mat-line *ngIf="!!(sideNavOpened$ | async)">
            <mat-icon class="nav-item" [svgIcon]="'base:'+sideNavigationItem.icon"></mat-icon> 
          </h4> -->
        </mat-list-item>
        <mat-list-item class="customLIDisclaimer" (click)="showTermsOfUseBreadcrumb()"
                      routerLink="/legal/terms-of-use/web" 
                      routerLinkActive="nav-item-active"
                      [routerLinkActiveOptions]="{exact: false}">
                
                <!-- Navigation closed-->
                <div *ngIf="!(sideNavOpened$ | async) else navigationOpenedDisclaimer">
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="assets/icons/Legal.png" width="25">
                  </div>
                </div>
                
                <!-- Navigation opened -->
                <ng-template #navigationOpenedDisclaimer>
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="assets/icons/Legal.png" width="25">
                    <div style="margin-left: 8px;">
                      {{ 'global.navigation.disclaimer' | translate }}
                    </div>
                  </div>
                </ng-template>
          <!-- <h4 mat-line *ngIf="!!(sideNavOpened$ | async)">
            <mat-icon class="nav-item" [svgIcon]="'base:'+sideNavigationItem.icon"></mat-icon> 
          </h4> -->
        </mat-list-item>


      </mat-nav-list>

      <div class="version-info" *ngIf="!!(sideNavOpened$ | async)">
        Service Office v{{ dsmVersion }}
      </div>
      

    </mat-drawer>    
  
    <!-- Page Content -->
    <ng-container *ngIf="breadCrumbData && !isOnIntroPage">
      <cl-breadcrumb [breadCrumbData]="breadCrumbData" ></cl-breadcrumb>
    </ng-container>
    
    <ng-container>
      <router-outlet></router-outlet>
    </ng-container>
    <!-- <div #dsmPageContent id="dsmPageContent" class="main-content-area">
      <router-outlet class="router-flex"></router-outlet>
    </div> -->
  </mat-drawer-container>
</div>